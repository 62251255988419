import React from 'react'
import Layout from '../components/layout';
import styled from 'styled-components';

const ContactGroup = styled.div`
/* border: solid 1px #718096; */
  border-radius: 5px;
  margin: 2rem auto;
  box-shadow: 0 20px 40px ${({ theme }) => theme.text};

  
  
  /*desktop version*/
  @media (min-width: 750px) {
    margin-top: 5rem;
    width:80ch;
    max-width: 85%;
    display: grid;
    place-items: center;
  }

  h3 {
    padding-top: .5rem;
    margin: 0 auto;
    color: ${({ theme }) => theme.text};
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 1.1;
    opacity: 0;
    animation: HeroAnimation;
    animation-duration: 3s;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.text};
    margin-bottom: 5px;
    opacity: 0;
    animation: HeroAnimation 3s 0.3s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
    text-align: center;
  }

  @keyframes HeroAnimation {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`

const Contact = () => {
  return (
    <Layout>
      <ContactGroup>
        <p>ACERA CONSTRUCT</p>
        <br />
        <p>Sediu operational: Str. Aghires nr 1Bis, sect 2,  Bucuresti</p>
        <p>Sediu legal: Str. Vasile Urseanu 06, sector 1, București</p>
        <br />
        <p>Tel: 0722 62 88 32 / 0757 222 997</p>
        <p>office@acera.ro</p>
      </ContactGroup>
    </Layout>
  )
}

export default Contact;